import { Box, Container, Flex, Icon, VStack } from "@chakra-ui/react"
import NormalHeading from "@components/headings/NormalHeading"
import StylizedHeading from "@components/headings/StylizedHeading"
import HeaderLayout from "@components/layout/HeaderLayout"
import { readItem, readItems } from "@directus/sdk"
import SvgOpenLink from "@public/svg/misc/open-link.svg"
import directusClient from "@utils/directus"
import { DateTime, Settings } from "luxon"
import { GetStaticProps, InferGetStaticPropsType, NextPage } from "next"
import dynamic from "next/dynamic"
import Image from "next/image"
import NextLink from "next/link"
import { useTranslations } from "next-intl"
import { NextSeo } from "next-seo"

const DynamicFooter = dynamic(() => import("@components/layout/Footer"), {
  ssr: false,
})

const Blog: NextPage = ({
  blogs,
  page_data,
  locales,
  locale,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  Settings.defaultLocale = "en"
  Settings.defaultZone = "Asia/Dubai"

  const t = useTranslations("blog")

  return (
    <>
      <NextSeo
        title={page_data.meta_title}
        description={page_data.meta_description}
        canonical={`${process.env.NEXT_PUBLIC_SITE_URL}/${page_data.slug}`}
        languageAlternates={locales.map((lang: string) => ({
          hrefLang: lang,
          href: `${process.env.NEXT_PUBLIC_SITE_URL}/${lang}/${page_data.slug}`,
        }))}
        additionalMetaTags={[
          {
            name: "keywords",
            content: page_data.meta_keywords,
          },
        ]}
      />
      <HeaderLayout />
      <Container
        maxW={"container.xl"}
        px={{ base: 10, xl: 0 }}
      >
        <NormalHeading as={"h1"}>{t("heading")}</NormalHeading>
        <StylizedHeading>{t("blogs")}</StylizedHeading>
        <Flex
          mt={100}
          justifyContent={"center"}
          alignItems={"stretch"}
          flexWrap={"wrap"}
        >
          {blogs.map(
            (blog: {
              id: number
              slug: string
              image: {
                filename_disk: string
              }
              title: string
              excerpt: string
              date_created: string
            }) => (
              <VStack
                as={NextLink}
                href={`/blog/${blog.slug}`}
                key={blog.id}
                width={300}
                m={4}
                shadow={"md"}
                align={"flex-start"}
                borderRadius={8}
                overflow={"hidden"}
                backgroundColor={"gray.50"}
                transition={"all 0.3s ease-in"}
                role={"group"}
                _hover={{
                  backgroundColor: "gray.100",
                }}
              >
                <Image
                  src={`${process.env.NEXT_PUBLIC_IMAGE_CDN}${blog.image.filename_disk}?key=blog-small`}
                  loader={({ src }) => src}
                  width={300}
                  height={169}
                  alt={blog.title}
                />
                <Box
                  width={"full"}
                  position={"relative"}
                  zIndex={1}
                  opacity={0}
                  transition={"all 0.3s ease-in"}
                  _groupHover={{ opacity: 1 }}
                >
                  <Icon
                    as={SvgOpenLink}
                    bg={"white"}
                    borderRadius={"full"}
                    width={12}
                    height={12}
                    color={"brand.secondary"}
                    position={"absolute"}
                    left={locale === "ar" ? 12 : "auto"}
                    right={locale === "en" ? 12 : "auto"}
                    top={-8}
                  />
                </Box>
                <Box
                  fontSize={"sm"}
                  px={4}
                >
                  {DateTime.fromISO(blog.date_created)
                    .setLocale(locale ?? "en")
                    .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
                </Box>
                <Box
                  color={"brand.black"}
                  px={4}
                  fontSize={"md"}
                  minH={100}
                >
                  {blog.title}
                </Box>
                <Box
                  color={"brand.black"}
                  p={4}
                  fontSize={"xs"}
                  fontStyle={"italic"}
                  borderTop={"3px dotted"}
                  borderTopColor={"brand.secondary"}
                >
                  {blog.excerpt}
                </Box>
              </VStack>
            )
          )}
        </Flex>
      </Container>
      <DynamicFooter />
    </>
  )
}

export default Blog

export const getStaticProps: GetStaticProps = async ({ locale, locales }) => {
  // Blogs
  const blogs = await directusClient
    .request(
      readItems("emenu_blogs", {
        sort: ["-date_created"],
        limit: -1,
        fields: [
          "id",
          "slug",
          // @ts-expect-error required fields
          "image.filename_disk",
          "date_created",
          { translations: ["*"] },
        ],
        filter: {
          status: {
            _eq: "published",
          },
        },
      })
    )
    .then((blogs) =>
      blogs.map((blog) => {
        const translations = blog?.translations?.find(
          (t) => t.languages_code === locale
        )
        return {
          ...blog,
          title: translations?.title,
          excerpt: translations?.excerpt,
        }
      })
    )

  // Page meta data
  const page_data = await directusClient
    .request(
      readItem("emenu_pages", 3, {
        fields: ["slug", { translations: ["*"] }],
      })
    )
    .then((res) => {
      const translations = res?.translations?.find(
        (t) => t.languages_id === locale
      )
      return {
        slug: res?.slug,
        meta_title: translations?.meta_title,
        meta_description: translations?.meta_description,
        meta_keywords: translations?.meta_keywords,
        title: translations?.title,
        content: translations?.content,
      }
    })

  return {
    props: {
      blogs,
      page_data,
      locales,
      locale,
      messages: {
        ...require(`@messages/shared/${locale}.json`),
        ...require(`@messages/blog/${locale}.json`),
      },
    },
  }
}
